.ribbon {
  background-color: var(--brown);
  overflow: hidden;
  white-space: nowrap;
  /* top left corner */
  position: absolute;
  left: -70px;
  top: 40px;
  /* for 45 deg rotation */
  transform: rotate(-45deg);
  /* for creating shadow */
  box-shadow: 0 0 10px var(--grey);
}
.ribbon h4 {
  border: 1px solid var(--grey);
  color: #fff;
  display: block;
  // font: bold 100% 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 1px 0;
  padding: 10px 80px;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  /* for creating shadow */
  text-shadow: 0 0 5px #444;
}

.featured_work {
  background-size: cover !important;
}

@media (min-width: 768px) {
  .featured_work {
    background-size: 100%;
  }
}
