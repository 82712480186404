.Preloader {
    // position: absolute;
    margin: 0px auto;
    height: 170px;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
  }
  .Preloader-squareContainer {
    // width: 500px;
    height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 10px;
  }
  .Preloader-square {
    width: 10px;
    height: 0px;
    background-color: #fff;
    margin-right: 10px;
  }
  .Preloader-square:last-child {
    margin-right: 0;
  }
  .Preloader-message {
    // font-family: Arial;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }
  