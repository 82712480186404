.animated-divider::after {
    content: "";
    width: 0%;
    height: 1px;
    display: block;
    border-bottom: 1px solid var(--brown);
    background-color: var(--brown);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: 0.6s var(--transition-ease--out-quintic);
  }
  
  .animated-divider-inview::after,
  .animated-divider-inview:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
    width: 100%;
  }