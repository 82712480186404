.preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  visibility: visible;
  opacity: 1;
  transition: all 3s var(--transition-ease--out-quintic) 1s;
  background-color: var(--grey);

  &-hidden {
    opacity: 0;
    z-index: 0;
    // visibility: hidden;
  }
}

.splash-container {
  margin-top: 30vh;
  transform: translateY(0vh);
  transition-delay: 1s;
  min-height: 100vh;
  transition: all 2.6s var(--transition-ease--out-quintic) 1s;

  &-translate {
    transform: translateY(-140vh);
  }
}
