.arrow-link::after {
  content: none;
}

.arrow-link svg {
  fill: var(--brown);
  transition: all 0.6s var(--transition-ease--out-quintic);
}

.left-arrow,
.right-arrow {
  transition: all 0.6s var(--transition-ease--out-quintic);
}

.left-arrow:hover {
  transform: translateX(-5px);
}

.right-arrow:hover {
  transform: translateX(5px);
}

.arrow-link:hover svg {
  fill: white;
}

.downArrow {
  position: absolute;
  // background-color: var(--brown);
  bottom: 25vh;
  // left: calc(50vw - 20px);
}
.bounce {
  -moz-animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  60% {
    -moz-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@media (min-width: 768px) {
  .downArrow {
    bottom: 15vh;
  }

  .slidenav__item--next {
    margin-right: 0.3rem;
  }
  .downArrow {
    position: absolute;
    // background-color: var(--brown);
    bottom: 5vh;
    // left: calc(50vw - 70px);
  }
  .slideshow {
    padding: 0;
    margin: 0;
  }
  .slide {
    height: 100vh;
  }
  .slide__img-wrap {
    width: 380px;
  }
  .preview__title {
    font-size: 2.5rem;
  }
  .preview__content {
    padding: 0 5rem;
    max-width: 600px;
  }
  .slide__img::after,
  .preview__img::after {
    display: none;
  }
}
