.card-image {
  // transition: .5s cubic-bezier(0.22, 1, 0.36, 1);
  transition: 0.5s ease-in-out;
}
.card-bg {
  transition: 0.5s ease-in-out;
  background-size: 100%;
}

.card:hover .card-image {
  transform: scale(1.11);
}

.card:hover .card-bg {
  background-size: 105%;
}
