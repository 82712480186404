
.menuicon {
  display: block;
  z-index: 1000;
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
  transform: rotate(0deg);
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &__bar,
  &__circle {
    fill: none;
    stroke: currentColor;
    stroke-width: 1;
    stroke-linecap: round;
  }
  &__bar {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-in-out;
  }
  &__circle {
    color: var(--grey);
    background-color: var(--grey);
    transition: stroke-dashoffset 0.3s linear 0.1s;
    stroke-dashoffset: circumference(23); // 23 is the <circle>'s radius
    stroke-dasharray: circumference(23);
  }

  &__bar:nth-child(2) {
    opacity: 0;
  }
  &__bar:nth-child(3) {
    opacity: 0;
  }
}
