@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-from-left {
  animation: 1s ease-out 3s 1 slideInFromLeft;
}

.parallax-image {
  min-width: 200vw;
  transform: translateY(60%) scale(1)  translateX(-20%);
}

@media (min-width: 768px) {
  .parallax-image {
    min-width: 130vw;
    transform: translateY(-20%) scale(.5);
  }
}