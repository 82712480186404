.image-container {
  transition: all 3s var(--transition-ease--out-quintic);
  clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
  &-holding {
    transform: scale(0.95);
  }
  &-right {
    transition-duration: 2s;
    clip-path: polygon(0 0, 75% 0, 100% 100%, 25% 100%);
  }

  &-left {
    transition-duration: 2s;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  }
}

.demo4 {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  height: 700px;
  position: relative;
}

.demo4-inner {
  overflow: hidden;
  position: relative;
  margin: auto;
}

.demo4-photo {
  position: absolute;
  background-color: lightgray;
}

/* .slick-container {
  width: 90%;
  height: 500px;
  margin: 5%;
  margin: 0 -27px;
}

.slick-slide {
  height: 320px;
  box-sizing: border-box;
  margin: 0 27px;
} */

/* .slick-slide img {
  height: 100%;
  width: 100%;
  border: 2.5px solid white;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
} */

.control {
  grid-column: 1/-1;
  grid-row: 1/2;
  background-color: var(--bg);
}

.control-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 37.5rem;
  padding: 1em;
  margin: auto;
}

input[type="range"] {
  display: block;
}

.result {
  grid-column: 1/-1;
  grid-row: 2/3;
  display: flex;
  justify-content: center;
}

.element-container {
  height: 100vh;
  /* border: 2px dashed #ccc; */
}

/* img {
  display: block;
  max-width: 100%;
} */

.element {
  height: 50vh;
  //   width: 50vh;
  object-fit: cover;
  margin: auto;
  animation: 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
  -webkit-clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
  clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
  -webkit-mask-size: 0;
  mask-size: 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}

.code-container {
  grid-column: 1/-1;
  grid-row: 3/4;
}

.code-container {
  text-align: center;
  background-color: #272822;
  padding: 1.3rem;
  color: #f8f8f2;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: Consolas, Monaco, "Andale Mono", monospace;
}

output {
  display: block;
}

.token-property {
  color: #f92672;
}

.token-function {
  color: #e6db74;
}

.token-selector {
  color: #a6e22e;
}

.source {
  grid-column: 1/2;
  grid-row: 4/-1;
}

.mask {
  grid-column: 2/-1;
  grid-row: 4/-1;
}

.source,
.mask {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg);
  padding: 1em;
}

.source img,
.mask img {
  width: 200px;
  height: 125px;
  margin: auto;
  object-fit: contain;
}

.label {
  cursor: pointer;
}

.label::after {
  content: "No Mask";
}

#switch:checked + .label::after {
  content: "Mask";
}

.scale {
  animation: mymove 1s cubic-bezier(1, 0.03, 1, 0.48) 1 normal forwards;
  -webkit-animation: mymove 1s cubic-bezier(1, 0.03, 1, 0.48) 1 normal forwards;
  -moz-animation: mymove 1s cubic-bezier(1, 0.03, 1, 0.48) 1 normal forwards;
  -o-animation: mymove 1s cubic-bezier(1, 0.03, 1, 0.48) 1 normal forwards;
}

.element-hover-forward {
  cursor: grabbing;
  animation: shrink 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
}

.element-hover-backward {
  cursor: grabbing;
  animation: shrinkreverse 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
}

.scaledown {
  cursor: grabbing;
  animation: scaledown 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
  animation-duration: 2s;
}

.element-open {
  /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%); */
  animation: mymove 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal forwards;
}

.open {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
}

.skew-back {
  animation: skewForward 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
}

.skew-forward {
  animation: skewBackward 2s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
}

.enlarge {
  animation: enlargeImg 0.4s cubic-bezier(0, 0.52, 0, 0.97) 1 normal;
}

@keyframes enlargeImg {
  from {
    transform: scale3d(1);
  }

  to {
    transform: scale3d(1.3);
  }
}

@keyframes skewForward {
  from {
    transform: skewX(0);
  }

  to {
    transform: skewX(-13deg);
  }
}

@keyframes skewBackward {
  from {
    transform: skewX(0);
  }

  to {
    transform: skewX(13deg);
  }
}

@keyframes mymove {
  0% {
    /* -webkit-mask-size: 100%;
    mask-size: 100% */
  }

  100% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

@keyframes shrink {
  0% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }

  100% {
    -webkit-clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
    clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
  }
}

@keyframes shrinkreverse {
  0% {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }

  100% {
    -webkit-clip-path: polygon(25% 0, 20% 0, 50% 100%, 50% 100%);
    clip-path: polygon(25% 0, 25% 0, 50% 100%, 50% 100%);
  }
}

@keyframes scaleup {
  from {
    transform: scale3d(0.8, 0.8, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scaledown {
  from {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(0.8, 0.8, 1);
  }
}

.slider-image {
  transition-delay: 1.5s;
  transition: 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  clip-path: polygon(40% 0, 40% 0, 60% 100%, 60% 100%);

  &-inview {
    transition: all 2s cubic-bezier(0.22, 1, 0.36, 1);
    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
  }

  &-holding {
    transform: scale(1.15);
  }
}

.slider-image:hover,
.slider-image-inview:hover {
  transform: scale(110%);
}
@media (min-width: 768px) {
  .slider-image {
    width: 100%;
  }

  .element {
    height: 50vh;
    object-fit: cover;
    margin: auto;
    -webkit-clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
    clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
    -webkit-mask-size: 0;
    mask-size: 0;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
  }
}

.swiper-pagination-bullet {
  margin-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 25px;
  height: 7px;
  margin-right: 10px;
  border-radius: 25%;
}

.slider-blurred {
  filter: blur(1px);
  transition: filter 0.1s var(--transition-ease--out-quintic);
}

.slider-not-blurred {
  filter: blur(0px);
}

.swiper-pagination-bullet {
  background-color: white;
}

.swiper-pagination-bullet-active {
  background-color: var(--brown);
}
