.side-navigation {
  border-right: 1px solid var(--brown);
  z-index: 50;
}

.nav {
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nav:target,
.nav-open {
  //animate the menu icon
  .menuicon {
    color: white;
    transform: rotate(180deg);
    z-index: 10;

    &__bar {
      color: var(--brown);
    }

    &__circle {
      stroke-dashoffset: 0;
      color: #fff;
    }
    &__bar:nth-child(1),
    &__bar:nth-child(4) {
      opacity: 0;
    }
    &__bar:nth-child(2) {
      opacity: 1;
      transform: rotate(45deg);
    }
    &__bar:nth-child(3) {
      opacity: 1;
      transform: rotate(-45deg);
    }
  }

  //show the nav items
  .nav {
    &__menu {
      visibility: visible;
    }

    &__item {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.menu-links {
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: none;

  &-visible{
    display: inline-block;
  }
}

