// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "variables";

body {
  margin: 0;
  color: white;
  font-family: "Noto Serif", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--grey);
  -webkit-tap-highlight-color: transparent;
  width: screen;
  overflow-x: hidden;
}

*:focus {
  outline: 0 !important;
}

input[type="button"]{
  outline:none;
}
input[type="button"]::-moz-focus-outer{
  outline:none;
}
input[type="button"]::-moz-focus-inner {
  border: 0;
}

ol {
  margin: auto;
  padding-left: 40px;
  list-style: decimal;
}

// background colors
.bg-grey {
  background: var(--grey);
}

.bg-brown {
  background: var(--brown);
}

.text-grey {
  color: var(--grey);
}

.text-brown {
  color: var(--brown);
}

p {
  line-height: 1.6;
  font-size: 16px;
}

h1 {
  font-size: 3.125em;
  line-height: 1.5em;
}

h2 {
  font-size: 1.618em;
}

h3 {
  font-size: 1.331em;
  line-height: 1.5em;
}

h4 {
  font-size: 1em;
}

@media (min-width: 768px) {
  p {
    line-height: 1.6;
    font-size: 16px;
  }

  h1 {
    font-size: 8.125em;
    line-height: 1.5em;
  }

  h2 {
    font-size: 3.618em;
  }

  h3 {
    font-size: 1.931em;
  }

  h4 {
    font-size: 1.818em;
  }
}

a.link {
  position: relative;
  display: inline-block;
  width: fit-content;
  width: -moz-fit-content;
}

a.link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.6s var(--transition-ease--out-quintic);
}

a.active::after,
a:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

a.no-style,
a.no-style:hover {
  &::after {
    content: "";
    width: 0 !important;
    height: 0 !important;
  }
}

a.underlined::after,
a.underlined::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transition: transform 1s var(--transition-ease--out-quintic);
}

h3 a.underlined::after,
h3 a.underlined::before {
  bottom: 0.2em;
}

a.underlined::after {
  transform: scaleX(1);
  transform-origin: bottom right;
  transition-delay: 0.2s;
}

a.underlined::before {
  transform: scaleX(0);
  transform-origin: bottom left;
}

a.underlined:hover::after {
  transform: scaleX(0);
  transition-delay: 0s;
}

a.underlined:hover::before {
  transform: scaleX(1);
  transition-delay: 0.2s;
}

.w-sans-side{
  width: calc(100vw-4rem)
}

.Toastify__progress-bar--dark {
  background: var(--brown) !important;
}