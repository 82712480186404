.animated-text-container {
  overflow: hidden;
  display: block;
}

.animated-text {
  transition: 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.5s;
}

.animated-text {
  position: relative;
  margin-top: 0px;
  white-space: nowrap;
}

.animated-text > .animated-text-wrapper {
  overflow: hidden;
}

.animated-text .animated-text-row {
  display: inline-block;
  transition: 2s cubic-bezier(0.22, 1, 0.36, 1);
  transform: translate3d(0, 110%, 0);
}

.animated-text-inview .animated-text-row {
  transform: translate3d(0, -4%, 0);
}

.animated-text-wrapper {
  // margin-top: -0.1em;
  height: 110%;
  display: block;
  margin-bottom: -0.2em;
}

.normal-animated-text {
  margin-right: 0.1em;
}

// a div.link{

// }
div.link h3.animated-text.animated-text-inview.text-white span.animated-text-wrapper span.animated-text-row{
  position: relative;
  display: inline-block;
  width: fit-content;
  width: -moz-fit-content;
}

div.link h3.animated-text.animated-text-inview.text-white span.animated-text-wrapper span.animated-text-row::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.6s var(--transition-ease--out-quintic);
}
div.link:hover h3.animated-text.animated-text-inview.text-white span.animated-text-wrapper span.animated-text-row::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}



// a div.link:hover::after {
//   transform-origin: bottom left;
//   transform: scaleX(1);
// }