.show-reel-btn-container {
  position: fixed;
  bottom: 10px;
  margin-left: -2px;
  right: 10px;
  z-index: 10;
  background: #fff;
  border-radius: 6px;
  border: solid 2px var(--brown);
  color: transparent;
  outline: none;
}

.show-reel-btn {
  width: 50px;
  height: 50px;
  display: inline-block;
  transition: all 0.6s var(--transition-ease--out-quintic);

  &-circle {
    fill: #fff;
  }

  &:hover {
    // scale: 1.1;

    .-circle {
      fill: #fff;
    }

    .st1 {
      &-red {
        fill: #ff0000;
        stroke: #ff0000;
      }
    }
  }
}

.st1 {
  &-red {
    fill: #ff0000;
    stroke: #ff0000;
  }
}

@media (min-width: 1025px) {
  .show-reel-btn-container {
    position: fixed;
    // left: 10px;
    bottom: 10px;
    z-index: 10000;
  }
  .show-reel-btn {
    width: 45px;
    height: 45px;

    &-circle {
      fill: #fff;
    }

    &-triangle {
      fill: var(--dark-grey);
      //   stroke: #000000;
      //   stroke-width: 2;
      stroke-miterlimit: 10;
    }
  }

  .st1 {
    &-red {
      fill: #ff0000;
      stroke: #ff0000;
    }
  }
}

.cls-2 {
  fill: none;
  stroke: rgb(146, 120, 90);
  stroke-miterlimit: 5;
  stroke-width: 3px;
}
