@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./core.scss";
@import "./article.scss";
@import "./card.scss";
@import "./fancydivider.scss";
@import "./fancyimage.scss";
@import "./fancyloader.scss";
@import "./fancyslider.scss";
@import "./fancytext.scss";
@import "./menuicon.scss";
@import "./modal.scss";
@import "./navigation.scss";
@import "./parallax.scss";
@import "./preloader.scss";
@import "./slidingtext.scss";
@import "./transitions.scss";
@import "./variables.scss";
@import "./work.scss";
@import "./works.scss";