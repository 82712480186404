/*Sentence*/
.sentence {
  font-size: 30px;
  text-align: left;
  line-height: 2em;
}
/*Wrapper*/
.wrapper {
  position: relative;
  width: 100%;
}

/*Vertical Sliding*/
.slidingText {
  display: inline;
  text-indent: 8px;
}
.slidingText span {
  animation: topToBottom 10s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.slidingText span:nth-child(2) {
  animation-delay: 2.5s;
}
.slidingText span:nth-child(3) {
  animation-delay: 5s;
}
.slidingText span:nth-child(4) {
  animation-delay: 7.5s;
}

/*topToBottom Animation*/
@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}